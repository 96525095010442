<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/customers">Kunden</router-link></li>
              <li>Kunde hinzufügen</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/customers" class="button button-grey button-small">Alle Kunden ›</router-link></p>
            </div>
          </header>

          <form @submit="create_customer" class="form" method="post" style="margin-top:20px">

            <div class="form-wrap">
              <label for="email">E-Mail</label>
              <input v-model="customer.email" class="form-input" type="text" name="email" id="email">
            </div>

            <div class="form-wrap">
              <label for="company">Unternehmen</label>
              <input v-model="customer.company" class="form-input" type="text" name="company" id="company">
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap">
                  <label for="first_name">Vorname</label>
                  <input v-model="customer.first_name" class="form-input" type="text" name="first_name" id="first_name">
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap">
                  <label for="last_name">Nachname</label>
                  <input v-model="customer.last_name" class="form-input" type="text" name="last_name" id="last_name">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-16">
                <div class="form-wrap">
                  <label for="street_name">Straße</label>
                  <input v-model="customer.street_name" class="form-input" type="text" name="street_name" id="street_name">
                </div>
              </div>
              <div class="col-8">
                <div class="form-wrap">
                  <label for="street_number">Hausnummer</label>
                  <input v-model="customer.street_number" class="form-input" type="text" name="street_number" id="street_number">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-8">
                <div class="form-wrap">
                  <label for="zip_code">Postleitzahl</label>
                  <input v-model="customer.zip_code" class="form-input" type="text" name="zip_code" id="zip_code">
                </div>
              </div>
              <div class="col-16">
                <div class="form-wrap">
                  <label for="city">Stadt</label>
                  <input v-model="customer.city" class="form-input" type="text" name="city" id="city">
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <label for="phone_number">Telefonnummer</label>
              <input v-model="customer.phone_number" class="form-input" type="text" name="phone_number" id="phone_number">
            </div>

            <div class="form-wrap">
              <label for="password">Passwort</label>
              <input v-model="customer.password" class="form-input" type="password" name="password" id="password">
            </div>

            <div class="form-wrap">
              <label for="birth_date">Geburtsdatum</label>
              <input v-model="customer.birth_date" class="form-input" type="text" name="birth_date" id="birth_date" placeholder="01.01.1990">
            </div>

            {{ errors }}

            <p>
              <input type="submit" value="Kunde erstellen ›" class="button button-orange button-small">
            </p>

          </form>


        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_customer',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      customer: {
        email: "",
        password: "",
        first_name: "",
        last_name: ""
      }
    }
  },
  methods: {
    create_customer: function (e) {
      e.preventDefault();

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/customers', this.customer, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$router.push('/admin/customers');
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })

    }
  },
  mounted () {}
}
</script>

<style lang="scss">

</style>
